.LogoFooter {
    display: flex;
    flex-direction: column;
    align-items: center;


}

.BKSC-logo {
    position:fixed;
    bottom: 1%;
    right:1%;
    height: 6vmin;
    pointer-events: none;
}