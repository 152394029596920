.LearnMore{
    margin-top: 5% ;
}


.illustration_midsize{
    height: 25vmin;
}
.illustration_fullsize{
    height: 40vmin;
}

.explanation_par{
    font-size: medium;
}

.img_desc{
    font-size: small;
    font-style: italic;
}

.endGoal {
    font-style: italic;

}

ul{
    bottom:0;
    left:0;
    text-align: left;
    font-size: medium;
}