.LogoFooter {
    display: flex;
    flex-direction: column;
    align-items: center;


}

.Sparta-logo {
    position:fixed;
    top: 1%;
    left:1%;
    height: 12vmin;
    pointer-events: none;

}