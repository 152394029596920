.ExtraLinks {
    position:absolute;
    bottom:0;
    left:0;
    list-style-type:none;
    text-align: left;


}

.ExtraLinks-links {
    color: #1e6cb6;
    float: left;
    font-size: 0.8em;

}

