.MessageResponse {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MessageResponse-img {
    position:relative;
    /*bottom: 1%;*/
    /*right:1%;*/
    height: 5vmin;
    pointer-events: none;
}

.MessageResponse-title {
    font-size: 20px;
}
.infoUpload{
    color: grey;
    font-size: medium;
}

.hash_info{
    font-size: 10px;
}