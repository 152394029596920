*{
    font-family: Open Sans,Arial,sans-serif;
}


.stampTitle{
    font-weight: bold;
    color: #1E6CB6;
}
.stampDesc{
    font-size: 25px;
    /*color: #1E6CB6;*/
}
#btn-primary{
    background-color: #1E6CB6;
    color:#1E6CB6;
}